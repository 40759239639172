import React from 'react'

import { ScholarshipForm } from '../Components/ScholarshipForm'

export const Scholarship = () => {
   
  return (
    <>
            {/* ScholarshipForm */}
            <ScholarshipForm/>  
    </>
  )
}
